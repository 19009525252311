import { configureStore, createAction  } from '@reduxjs/toolkit';
import dashboardReducer from './reducers/dashboard';
import statusColorsReducer from './reducers/color';

// Define the root state type
export type RootState = ReturnType<typeof store.getState>;

// Create the resetStore action
export const resetStore = createAction('store/reset');


// Create the Redux store
const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    statusColors: statusColorsReducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat((store) => (next) => (action) => {
    if (action.type === resetStore.type) {
      // Reset the state of all reducers
      Object.keys(store.getState()).forEach((key) => {
        store.dispatch({ type: `${key}/reset` });
      });
    }
    return next(action);
  }),
});

export default store;
