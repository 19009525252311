import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the state interfaces for status colors and priority colors
interface StatusColorsState {
  [status: string]: string;
}

interface PriorityColorsState {
  [priority: string]: string;
}

// Define the overall state interface
interface ColorsState {
  statusColors: StatusColorsState;
  priorityColors: PriorityColorsState;
}

// Set the initial state for the colors slice
const initialState: ColorsState = {
  statusColors: {},
  priorityColors: {},
};

// Create the statusColorsSlice using createSlice from Redux Toolkit
const statusColorsSlice = createSlice({
  name: "statusColors", // The name of the slice
  initialState, // The initial state
  reducers: {
    // Define the setStatusColor reducer
    setStatusColor: (state, action: PayloadAction<{ status: string; color: string }>) => {
      // Extract the payload data
      const { status, color } = action.payload;
      
      // Update the statusColors state with the new color for the given status
      state.statusColors[status] = color;
    },
    // Define the setPriorityColor reducer
    setPriorityColor: (state, action: PayloadAction<{ priority: string; color: string }>) => {
      // Extract the payload data
      const { priority, color } = action.payload;
      
      // Update the priorityColors state with the new color for the given priority
      state.priorityColors[priority] = color;
    },
    // Define the resetColors reducer
    resetColors: (state) => {
      // Reset the statusColors and priorityColors states to empty objects
      state.statusColors = {};
      state.priorityColors = {};
    },
  },
});

// Extract the action creators from the statusColorsSlice
export const { setStatusColor, setPriorityColor, resetColors } = statusColorsSlice.actions;

// Export the reducer function generated by statusColorsSlice
export default statusColorsSlice.reducer;
