import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Test } from '../../config/test';

// Define the state interface for the Dashboard slice
interface DashboardState {
  title: string;
  tests: Test[];
  uniqueRuns: Record<number, string>;
}

// Set the initial state for the Dashboard slice
const initialState: DashboardState = {
  title: '',
  tests: [],
  uniqueRuns: {},
};

// Create the dashboardSlice using createSlice from Redux Toolkit
const dashboardSlice = createSlice({
  name: 'dashboard', // The name of the slice
  initialState, // The initial state
  reducers: {
    // Define the setTitle reducer
    setTitle: (state, action: PayloadAction<string>) => {
      // Update the title state with the new value from the action payload
      state.title = action.payload;
    },
    // Define the setTests reducer
    setTests: (state, action: PayloadAction<Test[]>) => {
      // Update the tests state with the new array of tests from the action payload
      state.tests = action.payload;
    },
    // Define the setUniqueRuns reducer
    setUniqueRuns: (state, action: PayloadAction<Record<number, string>>) => {
      // Update the uniqueRuns state with the new object of unique runs from the action payload
      state.uniqueRuns = action.payload;
    },
    // Define the resetInfo reducer
    resetInfo: (state) => {
      // Reset the title, tests, and uniqueRuns states to their initial values
      state.title = '';
      state.tests = [];
      state.uniqueRuns = {};
    },
  },
});

// Extract the action creators from the dashboardSlice
export const { setTitle, setTests, setUniqueRuns, resetInfo } = dashboardSlice.actions;

// Export the reducer function generated by dashboardSlice
export default dashboardSlice.reducer;
