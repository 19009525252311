// Import necessary MUI components
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

/**
 * CircularBuffer Component displays a circular progress indicator with a buffering animation.
 */
export default function CircularBuffer() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "75vh" }}>
      <CircularProgress size={150} thickness={1.25} />
    </Box>
  );
}
