// import React and necessary components
import React from "react";
import LoadingComponent from "../components/context/Loading"; // import Loading component from the context module
// import react-router-dom components
import { Route, Routes, useNavigate } from "react-router-dom";

const Dashboard = React.lazy(() => import("../pages/Dashboard")); // Lazily load Dashboard component
const DisplayDetails = React.lazy(() => import("../pages/DisplayDetail")); // Lazily load DisplayDetails component
const Landing = React.lazy(() => import("../pages/Landing")); // Lazily load Landing component
const DisplayTestDetail = React.lazy(() => import("../pages/DisplayTestDetail")); // Lazily load DisplayTestDetail component

// define RouteSchema interface
interface RouteSchema {
  path: string;
  exact: boolean;
  component: JSX.Element;
}

const RedirectToLandingPage: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/landing"); // Redirect to "/landing" when the component mounts
  }, [navigate]);

  return null;
};

const routes: RouteSchema[] = [
  { path: "/", exact: true, component: <Landing /> }, // Route for the home page
  { path: "/home", exact: true, component: <Landing /> }, // Route for the home page
  { path: "/dashboard", exact: false, component: <Dashboard /> }, // Route for the dashboard page
  { path: "/display_details", exact: true, component: <DisplayDetails /> }, // Route for displaying details
  { path: "/display_details/:test_id/", exact: true, component: <DisplayTestDetail /> }, // Route for displaying test details with a specific test ID
  { path: "/landing", exact: false, component: <Landing /> }, // Route for the landing page
  { path: "*", exact: false, component: <RedirectToLandingPage /> } // Catch-all route to redirect to the landing page for any other routes
];

// Router Component
export default function Router() {
  return (
    <Routes>
      {routes.map((route: RouteSchema) => (
        <Route
          key={route.path}
          path={route.path}
          element={<React.Suspense fallback={<LoadingComponent />}>{route.component}</React.Suspense>}
        />
      ))}
    </Routes>
  );
}
