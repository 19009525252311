import CssBaseline from "@mui/material/CssBaseline"; // Importing the CssBaseline component from the MUI library
import { ThemeProvider } from "@mui/material/styles"; // Importing the ThemeProvider component from the MUI library
import Router from "./config/Router"; // Importing the Router component from the "./config/Router" file
import { themes } from "./config/themes"; // Importing the themes object from the "./config/themes" file
import "./App.css"; // Importing the "App.css" file for custom styles

export default function App(): JSX.Element {
  return (
    <ThemeProvider theme={themes}>
      {/* Applying the MUI theme to the entire application */}
      <CssBaseline />

      {/* Rendering the Router component */}
      <Router />
    </ThemeProvider>
  );
}
