// Import necessary MUI components
import CircularBuffer from "./CircularBuffer";
import Typography from "@mui/material/Typography";

/**
 * CircularBuffer Component displays a circular progress indicator with a buffering animation.
 */
export default function LoadingComponent() {
  return (
    <div>
      <CircularBuffer />
      <Typography variant="h6" align="center" fontWeight="bold">
        Loading... One moment please.
      </Typography>
    </div>
  );
}
