import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';

// Define the theme options using the ThemeOptions interface
export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#05419B', // Set the primary color to '#05419B'
    },
    secondary: {
      main: '#FFD507', // Set the secondary color to '#FFD507'
    },
  },
  typography: {
    fontFamily: 'Ovo, serif', // Set the default font family to 'Ovo, serif'
  },
};

// Create the theme using the theme options
export const themes = createTheme(themeOptions);
